import { InvoiceI } from './Invoice';
import { LanguageI } from './Language';
import { ProdocutI } from './Product';

export interface OrderI {
  orderCode?: string;
  contact?: ContactI;
  product?: ProdocutI;
  fromLanguage?: LanguageI;
  toLanguage?: LanguageI;
  isUrgent?: boolean;
  withNotarization?: boolean;
  totalNumberOfPages?: number;
  copiesNumber?: number;
  price?: PriceI;
  billingDetails?: BillingDetailsI;
  files?: Array<File>;
  withDelivery?: boolean;
  deliveryAddress?: string;
}

export interface ServerOrderI {
  customer: ContactI;
  priceDetails: SimpleOrderI;
  invoiceData: InvoiceI;
  deliveryAddress: string;
}

export interface ContactI {
  email?: string;
  phone?: string;
}
export interface SimpleOrderI {
  productId: string;
  fromLanguageId: string;
  toLanguageId: string;
  isUrgent: boolean;
  withNotarization: boolean;
  copiesNumber: number;
  withDelivery: boolean;
  totalNumberOfPages: number;
}

export interface PriceI {
  price: number;
  emergencyTax: number | null;
}

export interface BillingDetailsI {
  name: string;
  identifier: string;
  address: string;
  isCompany: boolean;
}
export interface PaymentDetails {
  orderCode: string | undefined;
  paymentProvider: string;
  paymentTransactionId?: string;
}

export const makeOrderI = (
  contact: ContactI,
  product: ProdocutI,
  fromLanguage: LanguageI,
  toLanguage: LanguageI,
  isUrgent: boolean,
  price: PriceI,
  billingDetails: BillingDetailsI,
  withDelivery: boolean,
  deliveryAddress: string
): OrderI => {
  return {
    contact,
    product,
    fromLanguage,
    toLanguage,
    isUrgent,
    price,
    billingDetails,
    withDelivery,
    deliveryAddress,
  };
};

export const makeSimpleOrderI = (
  productId: string,
  fromLanguageId: string,
  toLanguageId: string,
  isUrgent: boolean,
  withNotarization: boolean,
  copiesNumber: number,
  withDelivery: boolean,
  totalNumberOfPages: number
): SimpleOrderI => {
  return {
    productId,
    fromLanguageId,
    toLanguageId,
    isUrgent,
    withNotarization,
    copiesNumber,
    withDelivery,
    totalNumberOfPages,
  };
};

export const makePriceI = (
  price: number,
  emergencyTax: number | null
): PriceI => {
  return { price, emergencyTax };
};

export const makeContactI = (email: string, phone: string): ContactI => {
  return { email, phone };
};

export const makeServerOrderI = (
  customer: ContactI,
  priceDetails: SimpleOrderI,
  invoiceData: InvoiceI,
  deliveryAddress: string
): ServerOrderI => {
  return {
    customer,
    priceDetails,
    invoiceData,
    deliveryAddress,
  };
};

export const makeBillingDetailsI = (
  name: string,
  identifier: string,
  address: string,
  isCompany: boolean
): BillingDetailsI => {
  return { name, identifier, address, isCompany };
};
