export enum LocaleKey {
  RO = 'ro',
  GB = 'gb',
}

export enum ServerLocaleKey {
  RO = 'ro_RO',
  GB = 'en_US',
}

export const getLocaleKey = (str: string): LocaleKey => {
  // eslint-disable-next-line
  return (<any>LocaleKey)[str.toUpperCase()] || LocaleKey.RO;
};

export const getServerLocaleKeyByLocaleKey = (
  locale: LocaleKey
): ServerLocaleKey => {
  // eslint-disable-next-line
  return (<any>ServerLocaleKey)[locale.toUpperCase()] || ServerLocaleKey.RO;
};
