import React, { ChangeEvent, useState } from 'react';
import { Form, Row, Col, FormGroup, Input, Button, Tooltip } from 'reactstrap';
import { DropdownI, makeDropdownI } from '../../types/component/Dropdown';
import { TranslateInputKey } from '../../types/component/TranslateInputKey';
import { Dropdown } from '../atoms';
import config from '../../config.json';
import { useDispatch, useSelector } from 'react-redux';
import { appAddAlertAction } from '../../state/action';
import { AlertType, makeAlert } from '../../types/component/Alert';
import { selectAppSettings } from '../../state/selector/app';
import { convertLocaleTimeToUTCTimestamp } from '../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Trans } from '@lingui/react';

type Props = {
  products: Array<DropdownI>;
  fromLanguges: Array<DropdownI>;
  toLanguages: Array<DropdownI>;
  onSelect: (e: DropdownI | boolean | null, name: TranslateInputKey) => void;
  onNext: () => void;
  onBack: () => void;
  onAppend: (e: Array<File>) => void;
};

export const TranslateOrder: React.FunctionComponent<Props> = (
  props: Props
) => {
  const dispactch = useDispatch();
  const {
    products,
    onSelect,
    fromLanguges,
    toLanguages,
    onNext,
    onBack,
    onAppend,
  } = props;
  const [isNotarizedChecked, setIsNotarizedChecked] = useState(false);
  const appSettings = useSelector(selectAppSettings);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleOnClick = () => {
    onNext();
  };

  const handleFileChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesList = Array.from(e.target.files);

      if (Array.isArray(filesList)) {
        let filesSize = 0;

        filesList.forEach((element) => {
          filesSize += element.size;
        });

        if (
          filesSize >
          config.TRANSLATE_ORDER.MAX_UPLOADED_FILE_SIZE_MB * 1024 * 1024
        ) {
          dispactch(
            appAddAlertAction(
              makeAlert(
                `Fisier atasat depaseste limita de ${config.TRANSLATE_ORDER.MAX_UPLOADED_FILE_SIZE_MB} MB`,
                AlertType.ERROR
              )
            )
          );
          return;
        }

        onAppend(filesList);
      }
    }
  };

  const isEmergencyPossible = (): boolean => {
    if (appSettings && appSettings?.allowEmergenciesUntil) {
      return (
        convertLocaleTimeToUTCTimestamp(new Date()) <=
          new Date(appSettings.allowEmergenciesUntil).getTime() &&
        !isNotarizedChecked
      );
    }

    return false;
  };

  const isNotarizationPossible = (): boolean => {
    return (appSettings && appSettings?.allowNotarization) || false;
  };

  const getExtraCopyValues = (): Array<DropdownI> => {
    const values = [
      ...Array((config.TRANSLATE_ORDER.MAX_NUMBER_OF_COPIES || 9) + 1).keys(),
    ];

    return values.map((value) => makeDropdownI(value, value.toString()));
  };

  const getPagesValues = (): Array<DropdownI> => {
    const values = [
      ...Array((config.TRANSLATE_ORDER.MAX_NUMBER_OF_PAGES || 9) + 1).keys(),
    ];

    values.shift();

    return values.map((value) => makeDropdownI(value, value.toString()));
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Form>
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="translateOrder.document" />
          </label>
        </Col>
        <Col className="col-9 col-sm-9 col-md-9 align-self-center">
          <FormGroup>
            <Dropdown
              options={products}
              onSelect={(e) => onSelect(e, TranslateInputKey.PRODUCTS)}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="translateOrder.pages" />
          </label>
        </Col>
        <Col className="col-4 col-sm-4 col-md-4 align-self-start">
          <FormGroup>
            <Dropdown
              options={getPagesValues()}
              onSelect={(e) => onSelect(e, TranslateInputKey.PAGES)}
            />
          </FormGroup>
        </Col>
        <Col className="col-md-5 align-self-center"></Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="translateOrder.trsanslate" />
          </label>
        </Col>
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <FormGroup>
            <Dropdown
              options={fromLanguges}
              onSelect={(e) => onSelect(e, TranslateInputKey.FROM_LANGUAGES)}
            />
          </FormGroup>
        </Col>
        <Col className="col-1 col-sm-1 col-md-1 align-self-center d-none d-small-block d-md-block d-lg-block">
          <i className="ni ni-bold-right mb-3"></i>
        </Col>
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <FormGroup>
            <Dropdown
              options={toLanguages}
              onSelect={(e) => onSelect(e, TranslateInputKey.TO_LANGUAGES)}
              disabled={toLanguages.length === 0}
            />
          </FormGroup>
        </Col>
      </Row>
      {isEmergencyPossible() && (
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center">
            <label>
              <Trans id="translateOrder.urgent" />
            </label>
            &nbsp;
            <FontAwesomeIcon id="faInfoCircle" icon={faInfoCircle} />
            <Tooltip isOpen={tooltipOpen} toggle={toggle} target="faInfoCircle">
              <Trans id="translateOrder.urgentInfo" />{' '}
              {new Date(appSettings?.allowEmergenciesUntil || '').getHours()}
              :00 ! <br />
              <Trans id="translateOrder.urgentSubInfo" />
            </Tooltip>
          </Col>
          <Col className="col-2 col-sm-2 col-md-2 align-self-start">
            <FormGroup>
              <Input
                disabled={!isEmergencyPossible()}
                type="checkbox"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onSelect(e.target.checked, TranslateInputKey.URGENT);
                }}
              />
            </FormGroup>
          </Col>
          <Col className="col-md-7 align-self-center"></Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="translateOrder.legalization" />
          </label>
        </Col>
        <Col className="col-2 col-sm-2 col-md-2 align-self-start">
          <FormGroup>
            <Input
              disabled={!isNotarizationPossible()}
              type="checkbox"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setIsNotarizedChecked(e.target.checked);
                onSelect(true, TranslateInputKey.URGENT);
                onSelect(e.target.checked, TranslateInputKey.NOTARIZATION);
              }}
            />
          </FormGroup>
        </Col>
        <Col className="col-md-7 align-self-center"></Col>
      </Row>
      {isNotarizedChecked && (
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center">
            <label>
              <Trans id="translateOrder.extraCopy" />
            </label>
          </Col>
          <Col className="col-4 col-sm-4 col-md-4 align-self-start">
            <FormGroup>
              <Dropdown
                options={getExtraCopyValues()}
                onSelect={(e) => onSelect(e, TranslateInputKey.EXTRA_COPIES)}
              />
            </FormGroup>
          </Col>
          <Col className="col-md-5 align-self-center"></Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col className="col-3 col-sm-3 col-md-3 align-self-center">
          <label>
            <Trans id="translateOrder.file" />
          </label>
        </Col>
        <Col className="col-9 col-sm-9 col-md-9 align-self-center">
          <FormGroup>
            <Input
              type="file"
              name="file"
              onChange={handleFileChangeEvent}
              multiple={true}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <b>*</b>
          <Trans id="translateOrder.note" />
          <b>contact@verbit.ro</b>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-md-6"></Col>
        <Col>
          <Button color="danger" type="button" onClick={onBack}>
            <span className="btn-inner--icon">
              <i className="ni ni-bold-left"></i>
            </span>
          </Button>
          <Button color="success" type="button" onClick={handleOnClick}>
            <span className="btn-inner--icon">
              <i className="ni ni-bold-right"></i>
            </span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
