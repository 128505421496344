import React, { ChangeEvent, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import { makeContactUsMessageObject } from '../../types/logic/ContactUsMessage';
import { isEmptyString, isValidEmail } from '../../utils';
import config from '../../config.json';
import './ContactForm.css';
import { sendContactUsMessage } from '../../services';
import { AlertType, makeAlert } from '../../types/component/Alert';
import { appAddAlertAction, appSetLoaderAction } from '../../state/action';
import ReCAPTCHA from 'react-google-recaptcha';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/react';

export const ContactForm: React.FunctionComponent = () => {
  let recaptchaRef: ReCAPTCHA | null;
  const mounted = useRef(false);
  const dispactch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [messageSendRequested, setMessageSendRequested] = useState(false);
  const [formIsValidState, setFormIsValidState] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
    phone: false,
  });

  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const resetState = () => {
    if (!mounted.current) {
      return;
    }

    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    setPhone('');

    setFormIsValidState({
      name: false,
      email: false,
      subject: false,
      message: false,
      phone: false,
    });
  };

  const validateForm = () => {
    setFormIsValidState((prevState) => ({
      ...prevState,
      name: isEmptyString(name),
      email: !isValidEmail(email),
      subject: isEmptyString(subject),
      message: isEmptyString(message),
      phone: isEmptyString(phone),
    }));

    return (
      isEmptyString(name) ||
      !isValidEmail(email) ||
      isEmptyString(subject) ||
      isEmptyString(message) ||
      isEmptyString(phone)
    );
  };

  const onContactMeClick = () => {
    if (validateForm()) {
      return;
    }
    if (recaptchaRef) {
      recaptchaRef.execute();
      setMessageSendRequested(true);
    }
  };

  const handleMessageSend = () => {
    if (!messageSendRequested) {
      return;
    }

    setMessageSendRequested(false);
    dispactch(appSetLoaderAction(true));
    sendContactUsMessage(
      makeContactUsMessageObject(name, phone, email, subject, message)
    )
      .then((response) => {
        if (response.status === 201) {
          resetState();
          dispactch(
            appAddAlertAction(
              makeAlert('Multumim pentru mesaj! :)', AlertType.NOTE)
            )
          );
        }
      })
      .catch(() => {
        dispactch(
          appAddAlertAction(
            makeAlert(
              'Mesajul nu a fost trimit. Incercati din nou! :(',
              AlertType.ERROR
            )
          )
        );
      })
      .finally(() => {
        dispactch(appSetLoaderAction(false));
      });
  };

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target;

    if (!element) {
      return;
    }
    switch (element.name) {
      case 'name':
        if (element.value.length <= config.CONTACT.MAX_NAME_LENGHT) {
          setName(element.value);
        }
        setFormIsValidState((prevState) => ({
          ...prevState,
          name: false,
        }));
        break;
      case 'email':
        if (element.value.length <= config.CONTACT.MAX_EMAIL_LENGTH) {
          setEmail(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          email: false,
        }));
        break;
      case 'phone':
        if (element.value.length <= config.CONTACT.MAX_PHONE_LENGTH) {
          setPhone(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          phone: false,
        }));
        break;
      case 'subject':
        if (element.value.length <= config.CONTACT.MAX_SUBJECT_LENGTH) {
          setSubject(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          subject: false,
        }));
        break;
      case 'message':
        if (element.value.length <= config.CONTACT.MAX_MESSAGE_LENGTH) {
          setMessage(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          message: false,
        }));
        break;
    }
  };

  return (
    <Form>
      <Row>
        <Col md="12">
          <FormGroup>
            <Input
              id="contact_fullName"
              placeholder={i18n._('contact.fullName')}
              type="text"
              name="name"
              value={name}
              invalid={formIsValidState.name}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="5">
          <FormGroup>
            <Input
              id="contact_phone"
              placeholder={i18n._('contact.phoneNumber')}
              type="text"
              name="phone"
              value={phone}
              invalid={formIsValidState.phone}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <Input
              id="contact_email"
              placeholder={i18n._('contact.email')}
              type="email"
              name="email"
              value={email}
              invalid={formIsValidState.email}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Input
              id="contact_subject"
              placeholder={i18n._('contact.subject')}
              type="text"
              name="subject"
              value={subject}
              invalid={formIsValidState.subject}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <FormGroup>
            <Input
              id="contact_message"
              placeholder={i18n._('contact.message')}
              rows="4"
              type="textarea"
              name="message"
              value={message}
              invalid={formIsValidState.message}
              onChange={handleOnInputChange}
              className="no-resize"
            />
            <p className="message-length-label">
              {message.length}/{config.CONTACT.MAX_MESSAGE_LENGTH}
            </p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="7"></Col>
        <Col md="5">
          <Button
            className="float-right"
            color="primary"
            type="button"
            onClick={onContactMeClick}
          >
            <Trans id="contact.sendMessage"></Trans>
          </Button>
          <ReCAPTCHA
            ref={(ref) => (recaptchaRef = ref)}
            sitekey={`${process.env.REACT_APP_RECAPTCHA_CODE}`}
            size="invisible"
            onChange={() => handleMessageSend()}
          />
        </Col>
      </Row>
    </Form>
  );
};
