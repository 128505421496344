import React, { ChangeEvent, useState } from 'react';
import { Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { isEmptyString } from '../../utils';
import config from '../../config.json';
import { BillingDetailsI, makeBillingDetailsI } from '../../types/logic/Order';
import { Trans } from '@lingui/react';
import classnames from 'classnames';

type Props = {
  onBack: () => void;
  onNext: (e: BillingDetailsI) => void;
};
export const Billing: React.FunctionComponent<Props> = (props: Props) => {
  const [name, setName] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [address, setAddress] = useState('');
  const [formIsValidState, setFormIsValidState] = useState({
    name: false,
    identifier: false,
    address: false,
  });
  const [isCompany, setIsCompany] = useState(false);

  const { onNext, onBack } = props;

  const validateForm = () => {
    setFormIsValidState((prevState) => ({
      ...prevState,
      name: isEmptyString(name),
      identifier: isEmptyString(identifier) && isCompany,
      address: isEmptyString(address),
    }));

    return (
      isEmptyString(name) ||
      (isEmptyString(identifier) && isCompany) ||
      isEmptyString(address)
    );
  };

  const handleNextClick = () => {
    if (validateForm()) {
      return;
    }

    onNext(makeBillingDetailsI(name, identifier, address, isCompany));
  };

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target;

    if (!element) {
      return;
    }

    switch (element.name) {
      case 'name':
        if (element.value.length <= config.CONTACT.MAX_NAME_LENGHT) {
          setName(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          name: false,
        }));

        break;

      case 'identifier':
        if (element.value.length <= config.BILLING.MAX_IDENTIFIER_LENGHT) {
          setIdentifier(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          identifier: false,
        }));
        break;
      case 'address':
        if (element.value.length <= config.BILLING.MAX_ADDRESS_LENGHT) {
          setAddress(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          address: false,
        }));
        break;
      default:
        break;
    }
  };

  return (
    <Form>
      <Row className="mt-3">
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <label></label>
        </Col>
        <Col className="col-8 col-sm-8 col-md-8 align-self-center">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              className={classnames('btn', {
                'btn-primary': !isCompany,
                'btn-secondary': isCompany,
                active: !isCompany,
              })}
              onClick={() => {
                setIsCompany(false);
              }}
            >
              <input type="radio" name="options" id="option1" />
              <Trans id="billing.typePersonal" />
            </label>
            <label
              className={classnames('btn', {
                'btn-primary': isCompany,
                'btn-secondary': !isCompany,
                active: isCompany,
              })}
              onClick={() => {
                setIsCompany(true);
              }}
            >
              <input type="radio" name="options" id="option2" />
              <Trans id="billing.typeCompany" />
            </label>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <label>
            {isCompany ? (
              <Trans id="billing.nameCompany" />
            ) : (
              <Trans id="billing.namePersonal" />
            )}
          </label>
        </Col>
        <Col className="col-8 col-sm-8 col-md-8 align-self-center">
          <FormGroup>
            <Input
              id="billing_name"
              type="text"
              name="name"
              value={name}
              invalid={formIsValidState.name}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <label>
            {isCompany ? (
              <Trans id="billing.identifierCompany" />
            ) : (
              <Trans id="billing.identifierPersonal" />
            )}
          </label>
        </Col>
        <Col className="col-8 col-sm-8 col-md-8 align-self-center">
          <FormGroup>
            <Input
              id="billing_name"
              type="text"
              name="identifier"
              value={identifier}
              invalid={formIsValidState.identifier}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-4 col-sm-4 col-md-4 align-self-center">
          <label>
            <Trans id="billing.address" />
          </label>
        </Col>
        <Col className="col-8 col-sm-8 col-md-8 align-self-center">
          <FormGroup>
            <Input
              id="billing_name"
              type="text"
              name="address"
              value={address}
              invalid={formIsValidState.address}
              onChange={handleOnInputChange}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-6 col-sm-6 col-md-6"></Col>
        <Col>
          <Button color="danger" type="button" onClick={onBack}>
            <span className="btn-inner--icon">
              <i className="ni ni-bold-left"></i>
            </span>
          </Button>
          <Button color="success" type="button" onClick={handleNextClick}>
            <span className="btn-inner--icon">
              <i className="ni ni-bold-right"></i>
            </span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
