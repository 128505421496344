export enum TranslateInputKey {
  PRODUCTS = 'PRODUCTS',
  FROM_LANGUAGES = 'FROM_LANGUAGES',
  TO_LANGUAGES = 'TO_LANGUAGES',
  IS_URGENT = 'IS_URGENT',
  FORM_FILES = 'FORM_FILES',
  URGENT = 'URGENT',
  NOTARIZATION = 'NOTARIZATION',
  EXTRA_COPIES = 'EXTRA_COPIES',
  PAGES = 'PAGES',
  TRANSPORT = 'TRANSPORT',
  USE_BILLING_ADDRESS_FOR_TRANSPORT = 'USE_BILLING_ADDRESS_FOR_TRANSPORT',
  DELIVERY_TRANSPORT = 'DELIVERY_TRANSPORT',
}
