import { OrderI } from '../../types/logic/Order';
import { ProdocutI } from '../../types/logic/Product';
import { TranslateActionType, TRANSLATE_SET_ORDER } from '../action';
export interface TranslateReducer {
  products: Array<ProdocutI>;
  order: OrderI | null;
}

const initialState: TranslateReducer = {
  products: [],
  order: null,
};

type ActionType = TranslateActionType;

const translateReducer = (
  state: TranslateReducer = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case TRANSLATE_SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    default:
      return state;
  }
};

export default translateReducer;
