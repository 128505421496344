import React from 'react';
import { Alert } from 'reactstrap';
import { AlertType } from '../../types/component/Alert';
import './AlertComponent.css';

type Props = {
  alert: string;
  type: AlertType;
};

const AlertComponent: React.FunctionComponent<Props> = (props: Props) => {
  const { alert, type } = props;
  return (
    <Alert className="alert-animation" color={type} isOpen={true}>
      <strong>{alert}</strong>
    </Alert>
  );
};

export default AlertComponent;
