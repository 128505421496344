import { combineReducers } from 'redux';
import appReducer, { AppReducer } from './app';
import translateReducer, { TranslateReducer } from './translate';

export interface RootState {
  app: AppReducer;
  translate: TranslateReducer;
}

export const rootReducer = combineReducers({
  app: appReducer,
  translate: translateReducer,
});

// Reference to all reducer
const reducers = {
  appReducer,
  translateReducer,
};
export default reducers;
