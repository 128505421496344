import React from 'react';
import { NavItem, NavLink, Button } from 'reactstrap';
import { HeaderItemI, headerType } from '../../types/component';
import './HeaderItem.css';
import classNames from 'classnames';
import { NavLink as RRNavLink } from 'react-router-dom';
import { i18n } from '@lingui/core';

type HeaderItemProp = {
  headerItem: HeaderItemI;
  onClick: (e: string) => void;
  selected: boolean;
};

export const HeaderItem: React.FunctionComponent<HeaderItemProp> = (
  props: HeaderItemProp
) => {
  const { headerItem, onClick, selected } = props;

  const navItemClass = classNames({
    'show-selected': selected,
    hide: headerItem.disabled,
  });

  return (
    <NavItem className={navItemClass}>
      {headerItem.type === headerType.HEADER_BUTTON ? (
        <Button
          tag={RRNavLink}
          to={headerItem.value}
          color="success"
          outline
          type="button"
          //disabled={headerItem.disabled}
          onClick={() => onClick(headerItem.value)}
        >
          {i18n._(headerItem.title)}
        </Button>
      ) : (
        <NavLink
          tag={RRNavLink}
          to={headerItem.value}
          //disabled={headerItem.disabled}
          onClick={() => onClick(headerItem.value)}
        >
          {i18n._(headerItem.title)}
        </NavLink>
      )}
    </NavItem>
  );
};

export default HeaderItem;
