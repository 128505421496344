import { HeaderItemI } from '../../types/component';
import {
  AppActionType,
  APP_INIT,
  APP_UPDATE_ACTIVE_SCREEN,
  APP_ADD_ALERT_ACTION,
  APP_REMOVE_ALERT_ACTION,
  APP_SET_LOCALE_ACTION,
  APP_SET_LOADER_ACTION,
  APP_SET_SETTINGS_ACTION,
  APP_SET_PAYMENT_OVERLAY,
  APP_SET_PAYMENT_REDIRECT_URL,
} from '../action/app';
import { headerItemsMock } from '../../mocks';
import { AlertI } from '../../types/component/Alert';
import { removeArrayObject } from '../../utils/helper';
import { LocaleKey } from '../../types/logic/LocaleKey';
import { SettingsI } from '../../types/logic/Settings';
export interface AppReducer {
  activeScreen: string;
  headerItems: Array<HeaderItemI>;
  alerts: Array<AlertI>;
  locale: LocaleKey;
  loader: boolean;
  settings: SettingsI | null;
  paymentOverlayVisible: boolean;
  paymentRedirectURL: string | null;
}

const initialState: AppReducer = {
  activeScreen: '',
  headerItems: headerItemsMock,
  alerts: [],
  locale: LocaleKey.RO,
  loader: false,
  settings: null,
  paymentOverlayVisible: false,
  paymentRedirectURL: null,
};

type ActionType = AppActionType;

const appReducer = (state: AppReducer = initialState, action: ActionType) => {
  switch (action.type) {
    case APP_INIT:
      return {
        ...state,
        message: action.payload,
      };
    case APP_UPDATE_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.payload,
      };
    case APP_ADD_ALERT_ACTION:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case APP_REMOVE_ALERT_ACTION:
      return {
        ...state,
        alerts: removeArrayObject(state.alerts, action.payload),
      };
    case APP_SET_LOCALE_ACTION:
      return {
        ...state,
        locale: action.payload,
      };
    case APP_SET_LOADER_ACTION:
      return {
        ...state,
        loader: action.payload,
      };
    case APP_SET_SETTINGS_ACTION:
      return {
        ...state,
        settings: action.payload,
      };
    case APP_SET_PAYMENT_OVERLAY:
      return {
        ...state,
        paymentOverlayVisible: action.payload,
      };
    case APP_SET_PAYMENT_REDIRECT_URL:
      return {
        ...state,
        paymentRedirectURL: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
