import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Card,
  Col,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import {
  createOrder,
  getLanguageCombinations,
  getOrderPrice,
  getProducts,
} from '../../services/translate-service';
import { DropdownI, makeDropdownI } from '../../types/component/Dropdown';
import { makeProductI, ProdocutI } from '../../types/logic/Product';
import { Billing, TranslateOrder, Payment, Transport } from '../molecules';
import './Translate.scss';
import {
  LanguageCombinationI,
  makeLanguageCombinationI,
} from '../../types/logic/LanguageCombination';
import { LanguageI, makeLanguageI } from '../../types/logic/Language';
import { TranslateInputKey } from '../../types/component/TranslateInputKey';
import {
  BillingDetailsI,
  ContactI,
  makePriceI,
  makeServerOrderI,
  makeSimpleOrderI,
  OrderI,
  PaymentDetails,
} from '../../types/logic/Order';
import classnames from 'classnames';
import { SummaryItem } from '../atoms';
import { ContactOrder } from '../molecules/ContactOrder';
import { convertLocaleTimeToUTCTimestamp, formatPrice } from '../../utils';
import { makeInvoiceI } from '../../types/logic/Invoice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppLocale, selectAppSettings } from '../../state/selector';
import {
  appAddAlertAction,
  appSetLoaderAction,
  appSetSettingsAction,
} from '../../state/action';
import Alerts from './Alerts';
import { getCustomerSettings, paymentConfirmation } from '../../services';
import { makeSettingsI } from '../../types/logic/Settings';
import { AlertType, makeAlert } from '../../types/component/Alert';
import { PaymentProvider } from '../../types/logic/Payment';
import { Trans } from '@lingui/react';
import { i18n } from '@lingui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons';

export const Translate: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [translatePage, setTranslatePage] = useState(true);
  const [products, setProducts] = useState<Array<ProdocutI>>([]);
  const [toLanguages, setToLanguages] = useState<Array<DropdownI>>([]);
  const [activePanel, setActivePanel] = useState('0');
  const [languageCombinations, setLanguageCombinations] = useState<
    Array<LanguageCombinationI>
  >([]);
  const [order, setOrder] = useState<OrderI>();
  const [createdOrderNumber, setCreatedOrderNumber] = useState('');
  const locale = useSelector(selectAppLocale);
  const appSettings = useSelector(selectAppSettings);

  useEffect(() => {
    dispatch(appSetLoaderAction(true));
    getCustomerSettings()
      .then((response) => {
        const { data } = response;
        if (Array.isArray(data)) {
          dispatch(
            appSetSettingsAction(
              makeSettingsI(
                data[0].allowEmergenciesUntilUtc,
                data[0].allowNotarization
              )
            )
          );
        }
      })
      .finally(() => {
        dispatch(appSetLoaderAction(false));
      });
  }, []);

  useEffect(() => {
    dispatch(appSetLoaderAction(true));
    getProducts(locale)
      .then((response) => {
        const { data } = response;

        if (Array.isArray(data)) {
          const beProducts = data.map((product) =>
            makeProductI(
              product.id,
              product.title,
              product.description,
              product.price
            )
          );

          setProducts(beProducts);
        }
      })
      .finally(() => {
        dispatch(appSetLoaderAction(false));
      });
  }, [locale]);

  useEffect(() => {
    dispatch(appSetLoaderAction(true));
    getLanguageCombinations(locale)
      .then((response) => {
        const { data } = response;

        if (Array.isArray(data)) {
          const langCombinations: Array<LanguageCombinationI> = data.map(
            (langComb) => {
              return makeLanguageCombinationI(
                langComb.id,
                makeLanguageI(
                  langComb.fromLanguage.id,
                  langComb.fromLanguage.name
                ),
                makeLanguageI(langComb.toLanguage.id, langComb.toLanguage.name),
                langComb.priceCoefficient
              );
            }
          );

          setLanguageCombinations(langCombinations);
        }
      })
      .finally(() => {
        dispatch(appSetLoaderAction(false));
      });
  }, [locale, products]);

  useEffect(() => {
    handlePriceSimulation();
  }, [
    order?.product,
    order?.fromLanguage,
    order?.toLanguage,
    order?.totalNumberOfPages,
    order?.isUrgent,
    order?.withNotarization,
    order?.copiesNumber,
    order?.withDelivery,
  ]);

  useEffect(() => {
    setToLanguages(makeToLanguages());
  }, [order?.fromLanguage]);

  const handlePriceSimulation = () => {
    if (
      order &&
      order.product &&
      order.fromLanguage &&
      order.toLanguage &&
      JSON.stringify(order.fromLanguage) !== JSON.stringify(order.toLanguage)
    ) {
      const basicOrder = makeSimpleOrderI(
        order.product.id,
        order.fromLanguage.id,
        order.toLanguage.id,
        order.isUrgent === true,
        order.withNotarization === true,
        order.copiesNumber || 0,
        order.withDelivery === true,
        order.totalNumberOfPages || 1
      );
      dispatch(appSetLoaderAction(true));
      getOrderPrice(basicOrder)
        .then((response) => {
          if (response) {
            setOrder({
              ...order,
              price: makePriceI(
                response.data.price,
                response.data.emergencyTax
              ),
            });
          }
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 400) {
            handleServerErrors();
          }
        })
        .finally(() => {
          dispatch(appSetLoaderAction(false));
        });
    }
  };

  const handleServerErrors = () => {
    dispatch(appSetLoaderAction(true));
    getCustomerSettings()
      .then((response) => {
        const { data } = response;
        if (Array.isArray(data)) {
          if (
            convertLocaleTimeToUTCTimestamp(new Date()) >=
            new Date(data[0].allowEmergenciesUntilUtc).getTime()
          ) {
            dispatch(
              appAddAlertAction(
                makeAlert(i18n._('translate.error1'), AlertType.ERROR)
              )
            );
            setOrder({ ...order, isUrgent: false });
          } else if (
            data[0].allowNotarization !== appSettings?.allowNotarization &&
            order?.withNotarization === true &&
            data[0].allowNotarization === false
          ) {
            dispatch(
              appAddAlertAction(
                makeAlert(i18n._('translate.error2'), AlertType.ERROR)
              )
            );
            setOrder({ ...order, withNotarization: false });
          } else {
            dispatch(
              appAddAlertAction(
                makeAlert(i18n._('translate.error3'), AlertType.ERROR)
              )
            );
          }

          dispatch(
            appSetSettingsAction(
              makeSettingsI(
                data[0].allowEmergenciesUntilUtc,
                data[0].allowNotarization
              )
            )
          );
        }
      })
      .finally(() => {
        dispatch(appSetLoaderAction(false));
      });
  };

  const makeProducts = (): Array<DropdownI> => {
    return products.map((product) => makeDropdownI(product.id, product.title));
  };

  const makeFromLanguage = (): Array<DropdownI> => {
    if (
      !Array.isArray(languageCombinations) ||
      languageCombinations.length === 0
    ) {
      return [];
    }

    const languages: Array<DropdownI> = [];
    languageCombinations.forEach((langComb) => {
      const lang = languages.find(
        (l: DropdownI) => l.value === langComb.fromLanguage.id
      );

      if (!lang) {
        languages.push(
          makeDropdownI(langComb.fromLanguage.id, langComb.fromLanguage.name)
        );
      }
    });

    return languages;
  };

  const makeToLanguages = (): Array<DropdownI> => {
    if (!order || !order.fromLanguage) {
      return [];
    }

    const validCombinations = languageCombinations.filter(
      (langComb: LanguageCombinationI) =>
        langComb.fromLanguage.id === order.fromLanguage?.id
    );

    return validCombinations.map((langComb: LanguageCombinationI) =>
      makeDropdownI(langComb.toLanguage.id, langComb.toLanguage.name)
    );
  };

  const getLanguageFromCombinations = (
    id: string,
    type: TranslateInputKey
  ): LanguageI | null => {
    if (type === TranslateInputKey.FROM_LANGUAGES) {
      const languageComb:
        | LanguageCombinationI
        | undefined = languageCombinations.find(
        (langComb: LanguageCombinationI) => langComb.fromLanguage.id === id
      );

      return languageComb ? languageComb.fromLanguage : null;
    } else {
      const languageComb = languageCombinations.find(
        (langComb: LanguageCombinationI) => langComb.toLanguage.id === id
      );

      return languageComb ? languageComb.toLanguage : null;
    }
  };

  const handleProductSelect = (
    option: DropdownI | boolean | null,
    optionName: TranslateInputKey
  ) => {
    if (option === null) {
      return;
    }

    if (typeof option === 'boolean') {
      switch (optionName) {
        case TranslateInputKey.URGENT: {
          setOrder({ ...order, isUrgent: option });
          break;
        }
        case TranslateInputKey.NOTARIZATION: {
          let isUrgentValue = order?.isUrgent;
          if (order?.isUrgent === true && option === true) {
            isUrgentValue = false;
            dispatch(
              appAddAlertAction(
                makeAlert(i18n._('translate.error4'), AlertType.NOTE)
              )
            );
          }

          setOrder({
            ...order,
            withNotarization: option,
            isUrgent: isUrgentValue,
          });
          break;
        }
        case TranslateInputKey.TRANSPORT:
          {
            setOrder({ ...order, withDelivery: option });
          }
          break;
        case TranslateInputKey.USE_BILLING_ADDRESS_FOR_TRANSPORT: {
          setOrder({
            ...order,
            deliveryAddress: order?.billingDetails?.address,
          });
          break;
        }
        default:
          break;
      }
      return;
    }

    switch (optionName) {
      case TranslateInputKey.EXTRA_COPIES: {
        if (option && typeof option.value === 'number') {
          setOrder({ ...order, copiesNumber: option.value });
        }
        break;
      }
      case TranslateInputKey.PAGES: {
        if (option && typeof option.value === 'number') {
          setOrder({ ...order, totalNumberOfPages: option.value });
        }
        break;
      }
      case TranslateInputKey.PRODUCTS: {
        const prod = products.find(
          (prod: ProdocutI) => prod.id === option.value
        );
        if (prod) {
          setOrder({ ...order, product: prod });
        }
        break;
      }
      case TranslateInputKey.FROM_LANGUAGES: {
        const lang = getLanguageFromCombinations(
          option.value.toString(),
          optionName
        );
        if (lang) {
          setOrder({ ...order, fromLanguage: lang });
        }
        break;
      }
      case TranslateInputKey.TO_LANGUAGES: {
        const toLang = getLanguageFromCombinations(
          option.value.toString(),
          optionName
        );
        if (toLang) {
          setOrder({ ...order, toLanguage: toLang });
        }
        break;
      }
      default:
        //TODO: add unknown TranslateInputKey log
        break;
    }
  };

  const handleFileAppend = (toAppend: Array<File>) => {
    setOrder({ ...order, files: toAppend });
  };

  const makeOrder = () => {
    return new Promise((resolve, reject) => {
      dispatch(appSetLoaderAction(true));

      if (
        order &&
        order.product &&
        order.fromLanguage &&
        order.toLanguage &&
        JSON.stringify(order.fromLanguage) !==
          JSON.stringify(order.toLanguage) &&
        order.contact &&
        order.billingDetails
      ) {
        const basicOrder = makeSimpleOrderI(
          order.product.id,
          order.fromLanguage.id,
          order.toLanguage.id,
          order.isUrgent === true,
          order.withNotarization === true,
          order.copiesNumber || 0,
          order.withDelivery === true,
          order.totalNumberOfPages || 1
        );

        const invoiceDetails = makeInvoiceI(
          order.billingDetails.name,
          order.billingDetails.identifier
            ? order.billingDetails.identifier
            : 'N/A',
          order.billingDetails.address,
          order.billingDetails.isCompany
        );

        const serverOrder = makeServerOrderI(
          order?.contact,
          basicOrder,
          invoiceDetails,
          order && order.deliveryAddress ? order?.deliveryAddress : 'N/A'
        );

        const jsonServerOrder = new Blob([JSON.stringify(serverOrder)], {
          type: 'application/json',
        });
        const orderDetails = new FormData();
        orderDetails.append('saveOrder', jsonServerOrder, 'saveOrder.json');

        if (Array.isArray(order.files)) {
          order.files.forEach((file) => {
            orderDetails.append('files', file);
          });
        }

        dispatch(appSetLoaderAction(true));

        createOrder(orderDetails)
          .then((response) => {
            if (response.status === 201) {
              setCreatedOrderNumber('');
              if (response.data.code) {
                setOrder({ ...order, orderCode: response.data.code });
                const str = response.data.code.split('-');

                if (Array.isArray(str) && str.length > 0) {
                  setCreatedOrderNumber(str[1]);
                }

                dispatch(
                  appAddAlertAction(
                    makeAlert(
                      `${i18n._('translate.orderCreated')} ${str[1]}`,
                      AlertType.NOTE
                    )
                  )
                );

                resolve(true);
              }
            }
          })
          .catch((e) => {
            if (e && e.response && e.response.status === 400) {
              handleServerErrors();
              handlePriceSimulation();
              reject();
            }
          })
          .finally(() => {
            dispatch(appSetLoaderAction(false));
          });
      }
    });
  };

  const handlePaymentConfirmation = (paymentDetails: PaymentDetails | null) => {
    if (
      !paymentDetails ||
      paymentDetails.paymentProvider === PaymentProvider.BANK_TRANSFER
    ) {
      setTranslatePage(false);
      dispatch(appSetLoaderAction(false));
      return;
    }

    dispatch(appSetLoaderAction(true));
    paymentConfirmation(paymentDetails)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            appAddAlertAction(
              makeAlert(i18n._('payment.accepted'), AlertType.NOTE)
            )
          );
        }
      })
      .catch((e) => {
        if (e && e.response && e.response.status === 400) {
          dispatch(
            appAddAlertAction(
              makeAlert(i18n._('translate.error3'), AlertType.ERROR)
            )
          );
        }
      })
      .finally(() => {
        dispatch(appSetLoaderAction(false));
        setTranslatePage(false);
      });
  };

  const handleOnBackToStartEvent = () => {
    setTranslatePage(true);
    setActivePanel('0');
    setOrder(undefined);
  };

  return (
    <Container className="pt-7 translate">
      <Alerts />
      {translatePage ? (
        <Row className="pb-3 flex-column-reverse flex-sm-column-reverse d-flex flex-md-row flex-lg-row">
          <Col className="col-12 col-sm-12 col-md-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 col">
            <Card>
              <Row className="pt-4 top-details-panel">
                <Col>
                  {order && order.price ? (
                    <div className="summary-total">
                      <p className="total">
                        {order && order.price
                          ? formatPrice(order.price.price) + 'RON'
                          : '-'}
                      </p>
                      {order && order.isUrgent && order.price?.emergencyTax ? (
                        <p className="sub-total">
                          {i18n._('translate.urgentTaxInclude')}{' '}
                          {formatPrice(order.price?.emergencyTax)} RON
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className="pt-3 bottom-details-panel">
                <Col className="panel-tab">
                  <Row>
                    <Col>
                      <h6
                        className={classnames('header-label', {
                          active: activePanel === '0',
                        })}
                      >
                        <Trans id="translate.contactdetails" />
                      </h6>
                      <hr className="line-primary" />
                      <div className="summary-container">
                        {order && order.contact ? (
                          <div className="order-summary">
                            <SummaryItem
                              label={i18n._('translate.email')}
                              value={
                                order.contact.email ? order.contact.email : '-'
                              }
                            />
                            <SummaryItem
                              label={i18n._('translate.phone')}
                              value={
                                order.contact.phone ? order.contact.phone : '-'
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6
                        className={classnames('header-label', {
                          active: activePanel === '1',
                        })}
                      >
                        <Trans id="translate.translateOder" />
                      </h6>
                      <hr className="line-primary" />
                      <div className="summary-container">
                        {order &&
                        order.product &&
                        order.fromLanguage &&
                        order.toLanguage ? (
                          <div className="order-summary">
                            <SummaryItem
                              label={i18n._('translate.document')}
                              value={
                                order && order.product
                                  ? order.product.title
                                  : '-'
                              }
                            />
                            <SummaryItem
                              label={i18n._('translate.translate')}
                              value={
                                order && order.fromLanguage
                                  ? order.toLanguage
                                    ? `${order.fromLanguage?.name} - ${order.toLanguage.name}`
                                    : `${order.fromLanguage?.name} -`
                                  : '-'
                              }
                            />
                            <SummaryItem
                              label={i18n._('translate.urgent')}
                              value={
                                order && order.isUrgent
                                  ? i18n._('translate.yes')
                                  : i18n._('translate.no')
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6
                        className={classnames('header-label', {
                          active: activePanel === '2',
                        })}
                      >
                        <Trans id="translate.translateBilling" />
                      </h6>
                      <hr className="line-primary" />

                      <div className="summary-container">
                        {order && order.billingDetails ? (
                          <div className="order-summary">
                            <SummaryItem
                              label={i18n._('translate.name')}
                              value={
                                order.billingDetails.name
                                  ? order.billingDetails.name
                                  : '-'
                              }
                            />
                            <SummaryItem
                              label={i18n._('translate.identifier')}
                              value={
                                order.billingDetails.identifier
                                  ? order.billingDetails.identifier
                                  : '-'
                              }
                            />
                            <SummaryItem
                              label={i18n._('translate.address')}
                              value={
                                order.billingDetails.address
                                  ? order.billingDetails.address
                                  : '-'
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6
                        className={classnames('header-label', {
                          active: activePanel === '3',
                        })}
                      >
                        <Trans id="translate.translateDelivery" />
                      </h6>
                      <hr className="line-primary" />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6
                        className={classnames('header-label', {
                          active: activePanel === '4',
                        })}
                      >
                        <Trans id="translate.payment" />
                      </h6>
                      <hr className="line-primary" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="col-12 col-sm-12 col-md-8 ml-aut col">
            <Card>
              <TabContent activeTab={activePanel}>
                <TabPane tabId="0">
                  <Row>
                    <Col>
                      <h4 className="header-label">
                        <Trans id="translate.contactdetails" />
                      </h4>
                      <hr className="line-primary" />
                      <ContactOrder
                        onNext={(contact: ContactI) => {
                          setOrder({ ...order, contact });
                          setActivePanel('1');
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    <Col>
                      <h4 className="header-label">
                        <Trans id="translate.translateOder" />
                      </h4>
                      <hr className="line-primary" />
                      <TranslateOrder
                        products={makeProducts()}
                        onSelect={handleProductSelect}
                        onAppend={handleFileAppend}
                        fromLanguges={makeFromLanguage()}
                        toLanguages={toLanguages}
                        onNext={() => {
                          if (
                            order &&
                            order.product &&
                            order.totalNumberOfPages &&
                            order.fromLanguage &&
                            order.toLanguage &&
                            JSON.stringify(order.fromLanguage) !==
                              JSON.stringify(order.toLanguage) &&
                            Array.isArray(order.files) &&
                            order.files.length > 0
                          ) {
                            setActivePanel('2');
                          }
                        }}
                        onBack={() => {
                          setActivePanel('0');
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col>
                      <h4 className="header-label">
                        <Trans id="translate.translateBilling" />
                      </h4>
                      <hr className="line-primary" />
                      <Billing
                        onBack={() => {
                          setActivePanel('1');
                        }}
                        onNext={(billingDetails: BillingDetailsI) => {
                          setOrder({ ...order, billingDetails });
                          setActivePanel('3');
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col>
                      <h4 className="header-label">
                        {' '}
                        <Trans id="translate.translateDelivery" />
                      </h4>
                      <hr className="line-primary" />
                      <Transport
                        onSelect={handleProductSelect}
                        onBack={() => {
                          setActivePanel('2');
                        }}
                        onNext={(value, type) => {
                          if (
                            type === TranslateInputKey.DELIVERY_TRANSPORT &&
                            typeof value === 'string'
                          ) {
                            setOrder({ ...order, deliveryAddress: value });
                          }

                          makeOrder().then(() => {
                            setActivePanel('4');
                          });
                        }}
                        completedField={order?.deliveryAddress || ''}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col>
                      <h4 className="header-label">
                        <Trans id="translate.payment" />
                      </h4>
                      <hr className="line-primary" />
                      <Payment
                        orderCode={order?.orderCode}
                        amountToPay={order?.price?.price}
                        onNext={handlePaymentConfirmation}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      ) : (
        <Card className="pb-7">
          <Row className="pt-9">
            <Col>
              <FontAwesomeIcon icon={faFlagCheckered} />
            </Col>
          </Row>
          <Row className="pt-6">
            <Col>
              <Trans id="translate.orderCreated" />
              {createdOrderNumber}
            </Col>
          </Row>
          <Row className="pt-3">
            <Col>
              <Button
                color="primary"
                type="button"
                onClick={handleOnBackToStartEvent}
              >
                <Trans id="translate.newOrder" />
              </Button>
            </Col>
          </Row>
        </Card>
      )}
    </Container>
  );
};
