import React from 'react';
import '../assets/scss/circle-loader.scss';

export const Loader: React.FunctionComponent = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};
