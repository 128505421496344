import axios from 'axios';
import { PaymentDetails } from '../types/logic/Order';
import { EuPlatescRequestI } from '../types/logic/Payment';

const PAYMENT_EU_PLATESC_PATH = '/payment/eu-platesc';
const PAYMENT_CONFIRMATION_PATH = '/payment/confirmation';

export const requestEuPlatescPayment = (param: EuPlatescRequestI) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${PAYMENT_EU_PLATESC_PATH}`,
    param
  );
};

export const paymentConfirmation = (param: PaymentDetails) => {
  const params = new URLSearchParams();
  params.append('invoice_id', param.orderCode || '');
  if (param.paymentTransactionId) {
    params.append('transaction_id', param.paymentTransactionId);
  }

  // if (param.paymentProvider) {
  //   params.append('transaction_id', param.paymentProvider);
  // }

  return axios.post(
    `${process.env.REACT_APP_API_URL}${PAYMENT_CONFIRMATION_PATH}`,
    params.toString()
  );
};
