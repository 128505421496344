export interface InvoiceI {
  name: string;
  identificationNumber: string;
  address: string;
  isCompany?: boolean;
}

export const makeInvoiceI = (
  name: string,
  identificationNumber: string,
  address: string,
  isCompany: boolean
): InvoiceI => {
  return { name, identificationNumber, address, isCompany };
};
