export interface ContactUsMessageI {
  name: string;
  phone: string;
  email: string;
  subject: string;
  message: string;
}

export const makeContactUsMessageObject = (
  name: string,
  phone: string,
  email: string,
  subject: string,
  message: string
): ContactUsMessageI => {
  return { name, phone, email, subject, message };
};
