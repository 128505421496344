import React, { useState } from 'react';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import {
  Container,
  Navbar,
  NavbarBrand,
  UncontrolledCollapse,
  Row,
  Col,
} from 'reactstrap';
import './App.css';
import { useSelector } from 'react-redux';
import logo from './assets/images/logo.png';
import { Header, Home, Contact, Translate } from './components/templates';
import { selectAppLoader } from './state/selector';
import { Echipa } from './components/templates/Echipa';
import { Loader } from './components/Loader';
import classnames from 'classnames';
import { PaymentResult } from './components/templates/PaymentResult';
import { Trans } from '@lingui/react';

export const App: React.FunctionComponent = () => {
  const loaderVisible = useSelector(selectAppLoader);
  const [smallNavOpen, setSmallNavOpen] = useState(false);

  const toogleSmalNav = () => {
    setSmallNavOpen((prevState) => !prevState);
  };
  return (
    <div className="App">
      <Router>
        <Navbar
          className="navbar navbar-expand-lg bg-white navbar-absolute"
          expand="lg"
        >
          <Container>
            <NavbarBrand href="/" onClick={(e) => e.preventDefault()}>
              <img src={logo} width="170" />
            </NavbarBrand>
            <button
              aria-controls="navbar-default"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-default"
              data-toggle="collapse"
              id="navbar-default"
              type="button"
              onClick={toogleSmalNav}
              onTouchEnd={toogleSmalNav}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              navbar
              toggler="#navbar-default"
              isOpen={smallNavOpen}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-close" xs="12">
                    <button
                      aria-controls="navbar-default"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-default"
                      data-toggle="collapse"
                      id="navbar-default"
                      type="button"
                      onClick={toogleSmalNav}
                      onTouchEnd={toogleSmalNav}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Header onAction={toogleSmalNav} />
            </UncontrolledCollapse>
          </Container>
        </Navbar>
        <Routes>
          <Route path="/translate" element={<Translate />} />
          <Route path="/echipa" element={<Echipa />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/paymentResult" element={<PaymentResult />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
      <div>
        <div
          className={classnames({
            'app-loader-container': true,
            hidden: !loaderVisible,
          })}
        >
          <Loader />
        </div>
      </div>
    </div>
  );
};

export default App;
