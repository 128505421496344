import React, { ChangeEvent, useState } from 'react';
import { Form, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { ContactI, makeContactI } from '../../types/logic/Order';
import { isEmptyString, isValidEmail } from '../../utils';
import config from '../../config.json';
import classNames from 'classnames';
import './ContactOrder.scss';
import { Trans } from '@lingui/react';
import { SimpleModal } from './SimpleModal';
import { i18n } from '@lingui/core';
import terms from '../../assets/terms_and_cond.pdf';
import privacy from '../../assets/conf_policy.pdf';

type Props = {
  onNext: (e: ContactI) => void;
};

export const ContactOrder: React.FunctionComponent<Props> = (props: Props) => {
  const [infoModelVisible, setInfoModelVisible] = useState(false);
  const [infoModelVisiblePrivacy, setInfoModelVisiblePrivacy] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [formIsValidState, setFormIsValidState] = useState({
    email: false,
    phone: false,
    termsAndCoinditions: false,
    privacyPolicy: false,
  });

  const docs = [{ uri: terms }, { uri: privacy }];

  const { onNext } = props;

  const validateForm = () => {
    setFormIsValidState((prevState) => ({
      ...prevState,
      email: !isValidEmail(email),
      phone: isEmptyString(phone),
      termsAndCoinditions: !termsAndConditions,
      privacyPolicy: !privacyPolicy,
    }));

    return (
      !isValidEmail(email) ||
      isEmptyString(phone) ||
      !termsAndConditions ||
      !privacyPolicy
    );
  };

  const handleOnClick = () => {
    if (validateForm()) {
      return;
    }

    onNext(makeContactI(email, phone));
  };

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target;

    if (!element) {
      return;
    }

    switch (element.name) {
      case 'email':
        if (element.value.length <= config.CONTACT.MAX_EMAIL_LENGTH) {
          setEmail(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          email: false,
        }));

        break;

      case 'phone':
        if (element.value.length <= config.CONTACT.MAX_PHONE_LENGTH) {
          setPhone(element.value);
        }

        setFormIsValidState((prevState) => ({
          ...prevState,
          email: false,
        }));
        break;
      case 'termsAndConditions':
        setInfoModelVisible(true);
        setTermsAndConditions(element.checked);
        setFormIsValidState((prevState) => ({
          ...prevState,
          termsAndCoinditions: false,
        }));
        break;
      case 'privacyPolicy':
        setInfoModelVisiblePrivacy(true);
        setPrivacyPolicy(element.checked);
        setFormIsValidState((prevState) => ({
          ...prevState,
          privacyPolicy: false,
        }));
        break;
    }
  };

  return (
    <Form className="contact-order">
      <Row className="mt-3">
        <Col className="col-3 col-sm-4 col-md-4 align-self-center">
          <label>
            <Trans id="orderContact.email" />
          </label>
        </Col>
        <Col className="col-9 col-sm-8 col-md-8 align-self-center">
          <FormGroup>
            <Input
              type="text"
              name="email"
              value={email}
              invalid={formIsValidState.email}
              onChange={handleOnInputChange}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-3 col-sm-4 col-md-4 align-self-center">
          <label>
            <Trans id="orderContact.phone" />
          </label>
        </Col>
        <Col className="col-9 col-sm-8 col-md-8 align-self-center">
          <FormGroup>
            <Input
              type="text"
              name="phone"
              value={phone}
              invalid={formIsValidState.phone}
              onChange={handleOnInputChange}
            ></Input>
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-2 col-sm-1 col-md-1 align-self-center"></Col>
        <Col className="col-10 col-sm-11 col-md-11 align-self-start">
          <FormGroup
            className={classNames({
              'text-start': true,
              text: true,
              invalid: formIsValidState.termsAndCoinditions,
            })}
            onClick={() => {
              setInfoModelVisible(true);
            }}
          >
            <Input
              type="checkbox"
              name="termsAndConditions"
              checked={termsAndConditions}
              invalid={formIsValidState.termsAndCoinditions}
              onChange={handleOnInputChange}
              disabled={true}
            />
            <label className="d-inline">
              <Trans id="contactOrder.accept" />
            </label>
            &nbsp;
            <SimpleModal
              title={i18n._('contactOrder.dialogTitle')}
              visible={infoModelVisible}
              content={docs}
              contentIndex={0}
              onClose={() => {
                setTermsAndConditions(false);
                setInfoModelVisible(false);
              }}
              onAccept={() => {
                setTermsAndConditions(true);
                setInfoModelVisible(false);
              }}
            ></SimpleModal>
          </FormGroup>
          <FormGroup>
            <FormGroup
              className={classNames({
                'text-start': true,
                text: true,
                invalid: formIsValidState.privacyPolicy,
              })}
              onClick={() => {
                setInfoModelVisiblePrivacy(true);
              }}
            >
              <Input
                type="checkbox"
                name="privacyPolicy"
                checked={privacyPolicy}
                invalid={formIsValidState.privacyPolicy}
                onChange={handleOnInputChange}
                disabled={true}
              />
              <label className="d-inline">
                <Trans id="contactOrder.acceptPrivacy" />
              </label>
              &nbsp;
              <SimpleModal
                title={i18n._('contactOrder.dialogTitlePrivacy')}
                visible={infoModelVisiblePrivacy}
                content={docs}
                contentIndex={1}
                onClose={() => {
                  setPrivacyPolicy(false);
                  setInfoModelVisiblePrivacy(false);
                }}
                onAccept={() => {
                  setPrivacyPolicy(true);
                  setInfoModelVisiblePrivacy(false);
                }}
              ></SimpleModal>
            </FormGroup>
          </FormGroup>
        </Col>
        <Col className="col-md-7 align-self-center"></Col>
      </Row>
      <Row className="mt-3">
        <Col className="col-8 col-sm-8 col-md-8"></Col>
        <Col>
          <Button color="success" type="button" onClick={handleOnClick}>
            <span className="btn-inner--icon">
              <i className="ni ni-bold-right"></i>
            </span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
