import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './SimpleModal.scss';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { i18n } from '@lingui/core';

type Props = {
  title: string;
  visible: boolean;
  content: any;
  contentIndex: number;
  onClose: () => void;
  onAccept: () => void;
};

export const SimpleModal: React.FunctionComponent<Props> = (props: Props) => {
  const { visible, onClose, onAccept, title, content, contentIndex } = props;

  return (
    <Modal isOpen={visible} className="basic">
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <DocViewer
          documents={content}
          initialActiveDocument={content[contentIndex]}
          pluginRenderers={DocViewerRenderers}
          config={
            {
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
              pdfZoom: {
                defaultZoom: 1,
              },
              pdfVerticalScrollByDefault: true,
              // eslint-disable-next-line
            } as any
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onClose}>
          {i18n._('btn.decline')}
        </Button>
        <Button color="success" onClick={onAccept}>
          {i18n._('btn.accept')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
