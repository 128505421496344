import { HeaderItemI } from '../../types/component';
import { AlertI } from '../../types/component/Alert';
import { LocaleKey } from '../../types/logic/LocaleKey';
import { SettingsI } from '../../types/logic/Settings';
import { RootState } from '../reducer/index';

export const selectMenuItems = (state: RootState): Array<HeaderItemI> => {
  return state.app.headerItems;
};

export const selectAppActiveScreen = (state: RootState): string => {
  return state.app.activeScreen;
};

export const selectAppAlerts = (state: RootState): Array<AlertI> => {
  return state.app.alerts;
};

export const selectAppLocale = (state: RootState): LocaleKey => {
  return state.app.locale;
};

export const selectAppLoader = (state: RootState): boolean => {
  return state.app.loader;
};

export const selectAppSettings = (state: RootState): SettingsI | null => {
  return state.app.settings;
};

export const selectAppPaymentOverlayVisible = (state: RootState): boolean => {
  return state.app.paymentOverlayVisible;
};

export const selectAppPaymentRedirectURL = (
  state: RootState
): string | null => {
  return state.app.paymentRedirectURL;
};
