import React from 'react';
import classNames from 'classnames';
import { HeaderItemI } from '../../types/component';
import { HeaderItem } from '../atoms';
import { useSelector } from 'react-redux';
import { selectAppActiveScreen } from '../../state/selector';

import { Nav } from 'reactstrap';

type Props = {
  headerItems: Array<HeaderItemI>;
  align?: 'center' | 'right';
  onClick: (e: string) => void;
};

export const HeaderMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { headerItems, align, onClick } = props;
  const activeScreen = useSelector(selectAppActiveScreen);

  const getHeaderMenu = (elements: Array<HeaderItemI>) => {
    return elements.map((element: HeaderItemI) => {
      const selectedItem = element.value === activeScreen;

      return (
        <HeaderItem
          key={element.key}
          headerItem={element}
          onClick={onClick}
          selected={selectedItem}
        ></HeaderItem>
      );
    });
  };

  const menuClass = classNames('navbar-nav', {
    'navbar-right': align && align === 'right',
    'mx-auto': align && align === 'center',
  });

  return <Nav className={menuClass}>{getHeaderMenu(headerItems)}</Nav>;
};
export default HeaderMenu;
