export interface DropdownI {
  value: string | number;
  label: string;
}

export const makeDropdownI = (
  value: string | number,
  label: string
): DropdownI => {
  return { value, label };
};
