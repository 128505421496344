import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { ContactModule } from '../molecules';
import Alerts from './Alerts';
import { ContactForm } from '../molecules/ContactForm';
import './Contact.css';
import { Trans } from '@lingui/react';

export const Contact: React.FunctionComponent = () => {
  return (
    <Container className="pt-7 contact-section">
      <Alerts />
      <Row>
        <div className="col-md-12 text-center mb-4">
          <h1 className="display-1">
            {' '}
            <Trans id="contact.title" />
          </h1>
          <h3 className="lead">
            <Trans id="contact.subTitle" />
          </h3>
        </div>
      </Row>
      <Row>
        <div className="col-md-7 mx-auto mb-5">
          <ContactForm />
        </div>
      </Row>
      <Row>
        <Col lg="3" md="6" className="col-6 col-border">
          <ContactModule
            title="contact.address"
            value="Calea Mănăștur 48, Cluj-Napoca 400535"
            iconClass="ni-square-pin"
          />
        </Col>
        <Col lg="3" md="6" className="col-6 col-border">
          <ContactModule
            title="contact.email"
            value="contact@verbit.ro"
            iconClass="ni-email-83"
          />
        </Col>
        <Col lg="3" md="6" className="col-6 col-border">
          <ContactModule
            title="contact.phone"
            value="+(40) 755 945 629"
            iconClass="ni-mobile-button"
          />
        </Col>
        <Col lg="3" md="6" className="col-6">
          <ContactModule
            title="contact.contact"
            value="Alina Manu / Project Manager"
            iconClass="ni-circle-08"
          />
        </Col>
      </Row>
    </Container>
  );
};
