// eslint-disable-next-line
export const removeArrayObject = (array: Array<any>, object: any) => {
  if (!Array.isArray(array)) {
    return [];
  }

  const objectIndex = array.findIndex(
    (obj) => JSON.stringify(obj) === JSON.stringify(object)
  );

  switch (objectIndex) {
    case -1:
      return [];
    case 0:
      return array.slice(1, array.length);
    case array.length:
      return array.slice(0, array.length - 1);
    default:
      return array
        .slice(0, objectIndex)
        .concat(array.slice(objectIndex + 1, array.length));
  }
};
