export interface PaymentOptionI {
  currency: string;
  type: string;
  fee: number;
  foreign: boolean;
  originalPrice: number;
  value: number;
  price: number;
}

export const makePaymentOptionI = (
  currency: string,
  type: string,
  fee: number,
  foreign: boolean,
  originalPrice: number,
  value: number,
  price: number
) => ({
  currency,
  type,
  fee,
  foreign,
  originalPrice,
  value,
  price,
});

export interface EuPlatescRequestI {
  orderCode: string;
  amount: number;
  currency: string;
  orderDescription: string;
}

export const makeEuPlatescRequestI = (
  orderCode: string,
  amount: number,
  currency: string,
  orderDescription: string
): EuPlatescRequestI => ({
  orderCode,
  amount,
  currency,
  orderDescription,
});

export interface EuPlatescResponseI {
  amount: number;
  currency: string;
  invoiceId: string;
  orderDescription: string;
  redirectUrl: string;
}

export const makeEuPlatescResponseI = (
  amount: number,
  currency: string,
  invoiceId: string,
  orderDescription: string,
  redirectUrl: string
) => ({
  amount,
  currency,
  invoiceId,
  orderDescription,
  redirectUrl,
});

export enum PaymentProvider {
  BANK_TRANSFER = 'BANK_TRANSFER',
  EU_PLATESC = 'EU_PLATESC',
  PAYPAL = 'PAYPAL',
}
