import React from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import './PaymentCard.scss';
import classNames from 'classnames';

type Props = {
  id?: number;
  imageSrc?: string;
  color: string;
  title: string;
  description: string;
  onClick?: () => void;
  disabled?: boolean;
  size?: 'small';
  children?: React.ReactNode;
};

export const PaymentCard: React.FunctionComponent<Props> = (props: Props) => {
  const {
    id,
    title,
    description,
    imageSrc,
    color,
    disabled,
    onClick,
    children,
    size,
  } = props;

  const customClass = classNames({
    'card-disabled': disabled,
    'small-card': size === 'small',
  });

  return (
    <Card
      key={id}
      body
      color={color}
      className={`info text-left shadow paymentCard ${customClass}`}
      onClick={onClick}
    >
      <div className="option-container">
        <div className="bg-gradient-white shadow text-primary">
          {imageSrc && <img className="img img-raised" src={imageSrc} />}
        </div>
      </div>
      <CardTitle className="info-title pt-md-3 pt-lg-3" tag="h6">
        {title}
      </CardTitle>
      <CardText className="description">{description}</CardText>
      {children}
    </Card>
  );
};
