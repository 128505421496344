export interface AlertI {
  label: string;
  type: AlertType;
}

export enum AlertType {
  ERROR = 'danger',
  NOTE = 'success',
}

export const makeAlert = (label: string, type: AlertType) => {
  return { label, type };
};
