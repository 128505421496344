import { Trans } from '@lingui/react';
import React from 'react';

type Props = {
  title: string;
  value: string;
  iconClass: string;
};

export const ContactModule: React.FunctionComponent<Props> = (props: Props) => {
  const { title, value, iconClass } = props;

  const icon = `ni ${iconClass}`;
  return (
    <div className="info info-hover">
      <div className="icon icon-shape icon-shape-primary icon-lg shadow rounded-circle text-primary">
        <i className={icon}></i>
      </div>
      <h4 className="info-title">
        <Trans id={title} />
      </h4>
      <p className="description px-0">{value}</p>
    </div>
  );
};
