import { OrderI } from '../../types/logic/Order';

export const TRANSLATE_SET_ORDER = 'TRANSLATE_SET_ORDER';

interface TranslateSetOrderAction {
  type: typeof TRANSLATE_SET_ORDER;
  payload: OrderI;
}

export type TranslateActionType = TranslateSetOrderAction;

export const translateSetOrderAction = (
  payload: OrderI
): TranslateSetOrderAction => ({
  type: TRANSLATE_SET_ORDER,
  payload,
});
