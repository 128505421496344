import {
  HeaderItemI,
  makeHeaderItemObject,
  headerType,
} from '../types/component';

export const headerItemsMock: Array<HeaderItemI> = [
  makeHeaderItemObject(
    'b792689d-1827-4c51-96a4-88fb459c23ac',
    'header.home',
    '/',
    false
  ),
  /*makeHeaderItemObject(
    'a1221a46-77d7-44c7-9d19-378119cb2879',
    'Tarife',
    'tarife',
    false
  ),*/
  // makeHeaderItemObject(
  //   'e54c1d5a-35b7-4531-a5b0-cb2980c9a672',
  //   'Echipa',
  //   '/echipa',
  //   false
  // ),
  makeHeaderItemObject(
    '02b8a97b-cd44-4b8c-b217-aafad6db4d3f',
    'Contact',
    '/contact',
    false
  ),
  makeHeaderItemObject(
    'ed4a4a24-72c2-4117-a0f6-f468ce8916a9',
    'header.translate',
    '/translate',
    false,
    headerType.HEADER_BUTTON
  ),
];
