export interface HeaderItemI {
  key: string;
  title: string;
  value: string;
  disabled: boolean;
  type?: headerType;
}

export enum headerType {
  HEADER_LINK = 'link',
  HEADER_BUTTON = 'button',
}

export const makeHeaderItemObject = (
  key: string,
  title: string,
  value: string,
  disabled: boolean,
  type?: headerType
): HeaderItemI => {
  return { key, title, value, disabled, type };
};
