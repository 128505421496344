import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Tooltip,
} from 'reactstrap';
import { TranslateInputKey } from '../../types/component/TranslateInputKey';
import './Transport.scss';
import config from '../../config.json';
import { Trans } from '@lingui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
  onBack: () => void;
  onNext: (e: string, name: TranslateInputKey) => void;
  onSelect: (e: boolean | null, name: TranslateInputKey) => void;
  completedField: string;
};

export const Transport: React.FunctionComponent<Props> = (props: Props) => {
  const { onBack, onNext, onSelect, completedField } = props;

  const [addressDisabled, setAddressDisabled] = useState(true);
  const [reuseAddressDisabled, setReuseAddressDisabled] = useState(true);
  const [address, setAddress] = useState('');
  const [reuseAddress, setReuseAddress] = useState(false);
  const [tooltipOpenTransp, setTooltipOpenTransp] = useState(false);
  const [formIsValidState, setFormIsValidState] = useState({
    address: false,
  });

  useEffect(() => {
    reuseAddress ? setAddress(completedField) : setAddress('');
  }, [reuseAddress]);

  const handleOnInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const element = event.target;

    if (!element) {
      return;
    }

    switch (element.name) {
      case 'address':
        if (element.value.length <= config.BILLING.MAX_ADDRESS_LENGHT) {
          setAddress(element.value);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Container className="transport">
      <Form>
        <Row className="mt-3">
          <Col className="col-2 col-sm-2 col-md-2 align-self-center"></Col>
          <Col className="col-10 col-sm-10 col-md-10 align-self-center">
            <Trans id="delivery.pickUpNote" />
          </Col>
        </Row>
        <Row className="mt-3"></Row>
        <Row className="mt-3">
          <Col className="col-2 col-sm-2 col-md-2  align-self-center"></Col>
          <Col className="col-10 col-sm-10 col-md-10 align-self-center">
            <Trans id="delivery.curierNote" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center">
            <Trans id="delivery.curier" />
          </Col>
          <Col className="col-2 col-sm-2 col-md-2 align-self-start">
            <FormGroup>
              <Input
                type="checkbox"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setAddressDisabled(!e.target.checked);
                  setReuseAddressDisabled(!e.target.checked);
                  if (e.target.checked === false) {
                    setAddress('');
                  }
                  onSelect(e.target.checked, TranslateInputKey.TRANSPORT);
                }}
              />
            </FormGroup>
          </Col>
          <Col className="col-md-7 align-self-center"></Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center">
            <label>
              <Trans id="delivery.address" />
            </label>
          </Col>
          <Col className="col-9 col-sm-9 col-md-9 align-self-center">
            <FormGroup>
              <Input
                id="address"
                type="text"
                name="address"
                disabled={addressDisabled}
                value={address}
                onChange={handleOnInputChange}
                invalid={formIsValidState.address}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center">
            <label>
              <Trans id="delivery.country" />
            </label>
            &nbsp;
            <FontAwesomeIcon id="faInfoCircleTransp" icon={faInfoCircle} />
            <Tooltip
              isOpen={tooltipOpenTransp}
              toggle={() => setTooltipOpenTransp(!tooltipOpenTransp)}
              target="faInfoCircleTransp"
            >
              <Trans id="delivery.countryInfo" />{' '}
            </Tooltip>
          </Col>
          <Col className="col-9 col-sm-9 col-md-9 align-self-center">
            <FormGroup>
              <Input
                id="address"
                type="text"
                name="address"
                disabled={true}
                value="Romania"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-3 col-sm-3 col-md-3 align-self-center"></Col>
          <Col className="col-2 col-sm-2 col-md-2 align-self-start">
            <FormGroup>
              <Input
                disabled={reuseAddressDisabled}
                type="checkbox"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setReuseAddress(e.target.checked);
                  setAddressDisabled(e.target.checked);
                  onSelect(
                    e.target.checked,
                    TranslateInputKey.USE_BILLING_ADDRESS_FOR_TRANSPORT
                  );
                }}
              />
            </FormGroup>
          </Col>
          <Col className="col-7 col-sm-7 col-md-7 align-self-center">
            <Trans id="delivery.note" />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="col-6 col-sm-6 col-md-6"></Col>
          <Col>
            <Button color="danger" type="button" onClick={onBack}>
              <span className="btn-inner--icon">
                <i className="ni ni-bold-left"></i>
              </span>
            </Button>
            <Button
              color="success"
              type="button"
              onClick={() => {
                setFormIsValidState((prevState) => ({
                  ...prevState,
                  address: false,
                }));

                if (addressDisabled) {
                  onNext('N/A', TranslateInputKey.DELIVERY_TRANSPORT);
                  return;
                } else {
                  if (!address) {
                    setFormIsValidState((prevState) => ({
                      ...prevState,
                      address: true,
                    }));
                    return;
                  }
                }

                onNext(
                  address + ' Romania',
                  TranslateInputKey.DELIVERY_TRANSPORT
                );
              }}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-bold-right"></i>
              </span>
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
