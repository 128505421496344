import axios from 'axios';
import {
  getServerLocaleKeyByLocaleKey,
  LocaleKey,
} from '../types/logic/LocaleKey';
import { SimpleOrderI } from '../types/logic/Order';

const PRODUCT_PATH = '/product';
const LANGUAGE_COMBINATION_PATH = '/language-combination';
const ORDER_PRICE_SIMULATOR = '/order/price/simulation';
const ORDER_CREATE = '/order';

export const getProducts = (locale: LocaleKey | null) => {
  let queryParam = '';
  if (locale) {
    queryParam += `?locale=${getServerLocaleKeyByLocaleKey(locale)}`;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${PRODUCT_PATH}${queryParam}`
  );
};

export const getLanguageCombinations = (locale: LocaleKey | null) => {
  let queryParam = '';
  if (locale) {
    queryParam += `?locale=${getServerLocaleKeyByLocaleKey(locale)}`;
  }
  return axios.get(
    `${process.env.REACT_APP_API_URL}${LANGUAGE_COMBINATION_PATH}${queryParam}`
  );
};

export const getOrderPrice = (order: SimpleOrderI) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}${ORDER_PRICE_SIMULATOR}`,
    order
  );
};

export const createOrder = (order: FormData) => {
  return axios.post(`${process.env.REACT_APP_API_URL}${ORDER_CREATE}`, order);
};
