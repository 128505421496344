import React, { ReactNode } from 'react';
import { Card, CardTitle, CardText } from 'reactstrap';
import classNames from 'classnames';
import './ServiceCard.css';

type Props = {
  id?: number;
  icon: string;
  color: string;
  title: ReactNode;
  description: ReactNode;
  selected: number;
  onClick: (s: number) => void;
  extraClass?: string;
};

export const ServiceCard: React.FunctionComponent<Props> = (props: Props) => {
  const { id, title, description, icon, color, selected, extraClass } = props;

  const customClass = classNames(extraClass, {
    'card-selected': selected === id,
  });
  return (
    <Card
      key={id}
      body
      color={color}
      className={`info text-left shadow ${customClass}`}
    >
      <div className="icon icon-shape bg-gradient-white shadow rounded-circle text-primary">
        <i className={`ni text-info ${icon}`}></i>
      </div>
      <CardTitle className="info-title text-white" tag="h6">
        {title}
      </CardTitle>
      <CardText className="description text-white">{description}</CardText>
    </Card>
  );
};
