import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appSetPaymentRedirectURL } from '../../state/action';
import { Loader } from '../Loader';

export const PaymentResult: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(appSetPaymentRedirectURL('SUCCESS_P'));
  });

  return (
    <div>
      <Loader />
    </div>
  );
};
