import React from 'react';
import { Container, Row, Col, Card, CardBody, Badge } from 'reactstrap';
import './Echipa.css';
import avatarAM from '../../assets/images/avatar_alina_manu.jpeg';
import avatarMM from '../../assets/images/avatar_madalina_muntean.png';

export const Echipa: React.FunctionComponent = () => {
  return (
    <div className="contact-section">
      <div className="section card-container">
        <Container>
          <Row className="align-items-center">
            <Col md="6" lg="6" className="mr-auto">
              <Card className="card-profile">
                <div className="card-image">
                  <img
                    className="img img-raised rounded-circle"
                    src={avatarAM}
                  />
                </div>
                <CardBody>
                  <Container>
                    <Row>
                      <Col>
                        <h4 className="display-4">Alina Manu</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="lead mt-0 mb-1">
                          Traducator si interpret senior
                        </p>
                        <p className="lead mt-0 mb-1">Project Manager</p>
                      </Col>
                    </Row>
                    <Row className="row-spacer">
                      <Col className="pl-0">
                        <div className="text-left">
                          <Badge className="badge-circle badge-info mr-2">
                            <i className="ni ni-hat-3"></i>
                          </Badge>
                          Skills
                        </div>
                      </Col>
                      <Col className="pr-0 col-spacer">
                        <div className="text-right">Leadership</div>
                      </Col>
                    </Row>
                    <Row className="row-spacer">
                      <Col className="pl-0">
                        <div className="text-left">
                          <Badge className="badge-circle badge-info mr-2">
                            <i className="ni ni-hat-3"></i>
                          </Badge>
                          Hobbies
                        </div>
                      </Col>
                      <Col className="pr-0 col-spacer">
                        <div className="text-right">Skiing, Chess</div>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
            <Col md="6" lg="6">
              <Card className="card-profile">
                <div className="card-image">
                  <img
                    className="img img-raised rounded-circle"
                    src={avatarMM}
                  />
                </div>
                <CardBody>
                  <Container>
                    <Row>
                      <Col>
                        <h4 className="display-4">MADALINA MUNTEAN</h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p className="lead mt-0 mb-1">
                          Traducator si interpret junior
                        </p>
                        <p className="lead mt-0 mb-1">&nbsp;</p>
                      </Col>
                    </Row>
                    <Row className="row-spacer">
                      <Col className="pl-0">
                        <div className="text-left">
                          <Badge className="badge-circle badge-info mr-2">
                            <i className="ni ni-hat-3"></i>
                          </Badge>
                          Skills
                        </div>
                      </Col>
                      <Col className="pr-0 col-spacer">
                        <div className="text-right">Leadership</div>
                      </Col>
                    </Row>
                    <Row className="row-spacer">
                      <Col className="pl-0">
                        <div className="text-left">
                          <Badge className="badge-circle badge-info mr-2">
                            <i className="ni ni-hat-3"></i>
                          </Badge>
                          Hobbies
                        </div>
                      </Col>
                      <Col className="pr-0 col-spacer">
                        <div className="text-right">Skiing, Chess</div>
                      </Col>
                    </Row>
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
