import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderMenu from '../molecules/HeaderMenu';
import { selectAppLocale, selectMenuItems } from '../../state/selector';
import { appSetLocaleAction, appUpdateActiveScreen } from '../../state/action';
import FlagIcon from '../FlagIcons';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { LocaleKey, getLocaleKey } from '../../types/logic/LocaleKey';
import './Header.scss';

type Props = {
  onAction: () => void;
};

export const Header: React.FunctionComponent<Props> = (props: Props) => {
  const { onAction } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispactch = useDispatch();
  const headerItems = useSelector(selectMenuItems);
  const locale = useSelector(selectAppLocale);

  const headerButtons = headerItems.slice(headerItems.length - 1);
  const headerLinks = headerItems.slice(0, headerItems.length - 1);

  const handleClickEvent = (e: string) => {
    onAction();
    dispactch(appUpdateActiveScreen(e));
  };

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleLocaleUpdate = (locale: string) => {
    onAction();
    dispactch(appSetLocaleAction(getLocaleKey(locale)));
  };

  const getLocaleItems = () => {
    return Object.values(LocaleKey).map((value: string) => {
      return (
        <DropdownItem key={value} onClick={() => handleLocaleUpdate(value)}>
          <FlagIcon code={value} size="lg" />
        </DropdownItem>
      );
    });
  };

  return (
    <>
      <HeaderMenu
        align="center"
        headerItems={headerLinks}
        onClick={handleClickEvent}
      />
      <div className="header-locale">
        <Dropdown isOpen={dropdownOpen} toggle={toggle} inNavbar={true}>
          <DropdownToggle caret>
            <FlagIcon code={locale} size="lg" />
          </DropdownToggle>
          <DropdownMenu>{getLocaleItems()}</DropdownMenu>
        </Dropdown>
      </div>
      <HeaderMenu
        align="right"
        headerItems={headerButtons}
        onClick={handleClickEvent}
      />
    </>
  );
};

export default Header;
