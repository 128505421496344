export interface SettingsI {
  allowEmergenciesUntil?: string;
  allowNotarization?: boolean;
}

export const makeSettingsI = (
  allowEmergenciesUntil?: string,
  allowNotarization?: boolean
): SettingsI => {
  return { allowEmergenciesUntil, allowNotarization };
};
