import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { ServiceCard } from '../atoms';
import './Home.css';
import classNames from 'classnames';
import { Trans } from '@lingui/react';
import terms from '../../assets/terms_and_cond.pdf';
import privacy from '../../assets/conf_policy.pdf';
import shippingReturn from '../../assets/shipping_and_return.pdf';
export const Home: React.FunctionComponent = () => {
  const [activeCardKey, setActiveCardKey] = useState(1);
  const [activeCardTitle, setActiveCardTitle] = useState(
    <Trans id={`card_${activeCardKey}_title`} />
  );
  const [activeCardContent, setActiveCardContent] = useState(
    <Trans id={`card_${activeCardKey}_content`} />
  );

  useEffect(() => {
    handleAtiveCard(activeCardKey);
  }, []);

  const handleAtiveCard = (cardKey: number) => {
    setActiveCardKey(-1);

    setTimeout(() => {
      setActiveCardKey(cardKey);
      setActiveCardTitle(<Trans id={`card_${cardKey}_title`} />);
      setActiveCardContent(<Trans id={`card_${cardKey}_content`} />);

      setTimeout(() => {
        handleAtiveCard(cardKey + 1 > 6 ? 1 : cardKey + 1);
      }, 10000);
    }, 300);
  };

  const detailsClass = classNames('wrapper', {
    'hide-wrapper': activeCardKey === -1,
  });
  return (
    <>
      <Container className="section card-container">
        <Row className="align-items-center">
          <Col
            xs="12"
            sm="12"
            md="12"
            lg="5"
            className="mr-auto text-left details-body"
          >
            <div className={detailsClass}>
              <div className="pr-md-5 details-animation">
                <h3>{activeCardTitle}</h3>
                <p>{activeCardContent}</p>

                {activeCardKey == 5 && (
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-primary mr-3">
                            <i className="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            <Trans id={`card_${activeCardKey}_content_op1`} />
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-primary mr-3">
                            <i className="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            <Trans id={`card_${activeCardKey}_content_op2`} />
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="badge badge-circle badge-primary mr-3">
                            <i className="ni ni-check-bold"></i>
                          </div>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            <Trans id={`card_${activeCardKey}_content_op3`} />
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </Col>
          <Col xs="12" sm="12" md="12" lg="7" pl="0">
            <Row className="ml-4">
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={1}
                  color="info"
                  icon="ni-chat-round"
                  title={<Trans id="card_1_title" />}
                  description={<Trans id="card_1_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={2}
                  color="danger"
                  extraClass="info-raised"
                  icon="ni-paper-diploma"
                  title={<Trans id="card_2_title" />}
                  description={<Trans id="card_2_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={3}
                  color="default"
                  icon="ni-hat-3"
                  title={<Trans id="card_3_title" />}
                  description={<Trans id="card_3_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
            </Row>
            <Row className="ml-4">
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={4}
                  color="primary"
                  icon="ni-headphones"
                  title={<Trans id="card_4_title" />}
                  description={<Trans id="card_4_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={5}
                  color="warning"
                  extraClass="info-raised"
                  icon="ni-single-copy-04"
                  title={<Trans id="card_5_title" />}
                  description={<Trans id="card_5_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
              <Col xs="4" sm="4" lg="4" md="4">
                <ServiceCard
                  id={6}
                  color="success"
                  icon="ni-single-02"
                  title={<Trans id="card_6_title" />}
                  description={<Trans id="card_6_description" />}
                  selected={activeCardKey}
                  onClick={handleAtiveCard}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container className="text-center text-md-start footer-container">
        <Row>
          <Col md="3" lg="4" xl="3" className="mx-auto">
            <div className="description px-0 text-reset">SC Verbit SRL</div>
            <div className="description px-0 text-reset">CUI: 45627850</div>
            <div className="description px-0 text-reset">
              Nr.ORC: J12/738/2022
            </div>
          </Col>
          <Col md="3" lg="4" xl="3" className="mx-auto mb-1">
            <div>
              <a
                href={terms}
                target="_blank"
                rel="noreferrer"
                className="description px-0 text-reset"
              >
                <Trans id="contactOrder.dialogTitle" />
              </a>
            </div>
            <div>
              <a
                href={privacy}
                className="description px-0 text-reset"
                target="_blank"
                rel="noreferrer"
              >
                <Trans id="contactOrder.dialogTitlePrivacy" />
              </a>
            </div>
            <div>
              <a
                href={shippingReturn}
                className="description px-0 text-reset"
                target="_blank"
                rel="noreferrer"
              >
                <Trans id="contactOrder.dialogTitleShippingReturn" />
              </a>
            </div>
          </Col>
          <Col md="3" lg="4" xl="3" className="mx-auto mb-4">
            <div>
              <a
                href="https://anpc.ro/ce-este-sal/?ref=footer_3_5"
                className="description px-0 text-reset"
                target="_blank"
                rel="noreferrer"
              >
                ANPC-SAL
              </a>
            </div>
            <div>
              <a
                href="https://anpc.ro/ce-este-sal/"
                className="description px-0 text-reset"
                target="_blank"
                rel="noreferrer"
              >
                ANPC
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
