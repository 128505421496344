export interface ProdocutI {
  id: string;
  title: string;
  description: string;
  price: number;
}

export const makeProductI = (
  id: string,
  title: string,
  description: string,
  price: number
): ProdocutI => {
  return { id, title, description, price };
};
