import React, { ReactElement, useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { messages as roMesages } from '../assets/locales/ro/messages';
import { messages as enMessages } from '../assets/locales/en/messages';
import { LocaleKey } from '../types/logic/LocaleKey';
import { useSelector } from 'react-redux';
import { selectAppLocale } from '../state/selector';

type Props = {
  children: ReactElement;
};

export const LocaleProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  useEffect(() => {
    i18n.load(LocaleKey.RO, roMesages);
    i18n.load(LocaleKey.GB, enMessages);
  });

  const locale = useSelector(selectAppLocale);

  useEffect(() => {
    i18n.activate(locale);
  }, [locale]);

  return <I18nProvider i18n={i18n}>{props.children}</I18nProvider>;
};
