import moment from 'moment';

export const formatPrice = (price: number, dec?: number) => {
  if (!price) {
    return '-';
  }

  if (!dec) {
    return price / 100;
  }

  return (price / 100).toFixed(dec);
};

export const convertLocaleTimeToUTCTimestamp = (date: Date) => {
  return moment.utc(date).valueOf();
};
