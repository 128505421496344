import { LanguageI } from './Language';

export interface LanguageCombinationI {
  id: string;
  fromLanguage: LanguageI;
  toLanguage: LanguageI;
  priceCoefficient: number;
}

export const makeLanguageCombinationI = (
  id: string,
  fromLanguage: LanguageI,
  toLanguage: LanguageI,
  priceCoefficient: number
): LanguageCombinationI => {
  return { id, fromLanguage, toLanguage, priceCoefficient };
};
