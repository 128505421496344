import React from 'react';
import { Row, Col } from 'reactstrap';
import './SummaryItem.scss';
type Props = {
  label: string;
  value: string;
};
export const SummaryItem: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Row>
      <Col className="col-md-5">
        <p className="summary-label text-left">{props.label}:</p>
      </Col>
      <Col className="col-md-7 pl-0">
        <p className="summary-value text-left">{props.value}</p>
      </Col>
    </Row>
  );
};
