import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { DropdownI } from '../../types/component/Dropdown';

type Props = {
  options: Array<DropdownI>;
  disabled?: boolean;
  onSelect: (e: DropdownI | null) => void;
};

export const Dropdown: React.FunctionComponent<Props> = (props: Props) => {
  const [selection, setSelection] = useState(null);

  const { options, onSelect, disabled } = props;

  const prevOptionProp = useRef<Array<DropdownI>>([]);
  useEffect(() => {
    if (JSON.stringify(options) !== JSON.stringify(prevOptionProp.current)) {
      setSelection(null);
      onSelect(null);
    }
    prevOptionProp.current = options;
  }, [options]);

  // eslint-disable-next-line
  const handleOnChangeEvent = (e: any) => {
    setSelection(e);
    onSelect(e);
  };

  return (
    <Select
      options={options}
      onChange={handleOnChangeEvent}
      isDisabled={disabled}
      value={selection}
    />
  );
};
