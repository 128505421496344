import { AlertI } from '../../types/component/Alert';
import { LocaleKey } from '../../types/logic/LocaleKey';
import { SettingsI } from '../../types/logic/Settings';

export const APP_INIT = 'APP_INIT';
export const APP_UPDATE_ACTIVE_SCREEN = 'APP_UPDATE_ACTIVE_SCREEN';
export const APP_ADD_ALERT_ACTION = 'APP_ADD_ALERT';
export const APP_REMOVE_ALERT_ACTION = 'APP_REMOVE_ALERT_ACTION';
export const APP_SET_LOCALE_ACTION = 'APP_SET_LOCALE_ACTION';
export const APP_SET_LOADER_ACTION = 'APP_SET_LOADER_ACTION';
export const APP_SET_SETTINGS_ACTION = 'APP_SET_SETTINGS_ACTION';
export const APP_SET_PAYMENT_OVERLAY = 'APP_SET_PAYMENT_OVERLAY';
export const APP_SET_PAYMENT_REDIRECT_URL = 'APP_SET_PAYMENT_REDIRECT_URL';

interface AppInitAction {
  type: typeof APP_INIT;
  payload: string;
}

interface AppUpdateActiveScreen {
  type: typeof APP_UPDATE_ACTIVE_SCREEN;
  payload: string;
}

interface AppAddAlertAction {
  type: typeof APP_ADD_ALERT_ACTION;
  payload: AlertI;
}

interface AppRemoveAlertAction {
  type: typeof APP_REMOVE_ALERT_ACTION;
  payload: AlertI;
}
interface AppSetLocaleAction {
  type: typeof APP_SET_LOCALE_ACTION;
  payload: LocaleKey;
}

interface AppSetLoaderAction {
  type: typeof APP_SET_LOADER_ACTION;
  payload: boolean;
}

interface AppSetSettingsAction {
  type: typeof APP_SET_SETTINGS_ACTION;
  payload: SettingsI;
}

interface AppSetPayentOverlay {
  type: typeof APP_SET_PAYMENT_OVERLAY;
  payload: boolean;
}

interface AppSetPaymentRedurectURL {
  type: typeof APP_SET_PAYMENT_REDIRECT_URL;
  payload: string;
}

export type AppActionType =
  | AppInitAction
  | AppUpdateActiveScreen
  | AppAddAlertAction
  | AppRemoveAlertAction
  | AppSetLocaleAction
  | AppSetLoaderAction
  | AppSetSettingsAction
  | AppSetPayentOverlay
  | AppSetPaymentRedurectURL;

export const appInit = (payload: string): AppInitAction => ({
  type: APP_INIT,
  payload,
});

export const appUpdateActiveScreen = (
  payload: string
): AppUpdateActiveScreen => ({
  type: APP_UPDATE_ACTIVE_SCREEN,
  payload,
});

export const appAddAlertAction = (payload: AlertI): AppAddAlertAction => ({
  type: APP_ADD_ALERT_ACTION,
  payload,
});

export const appRemoveAlertAction = (
  payload: AlertI
): AppRemoveAlertAction => ({
  type: APP_REMOVE_ALERT_ACTION,
  payload,
});

export const appSetLocaleAction = (payload: LocaleKey): AppSetLocaleAction => ({
  type: APP_SET_LOCALE_ACTION,
  payload,
});

export const appSetLoaderAction = (payload: boolean): AppSetLoaderAction => ({
  type: APP_SET_LOADER_ACTION,
  payload,
});

export const appSetSettingsAction = (
  payload: SettingsI
): AppSetSettingsAction => ({
  type: APP_SET_SETTINGS_ACTION,
  payload,
});

export const appSetPaymentOverlay = (
  payload: boolean
): AppSetPayentOverlay => ({
  type: APP_SET_PAYMENT_OVERLAY,
  payload,
});

export const appSetPaymentRedirectURL = (
  payload: string
): AppSetPaymentRedurectURL => ({
  type: APP_SET_PAYMENT_REDIRECT_URL,
  payload,
});
