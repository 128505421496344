import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppAlerts } from '../../state/selector';
import { AlertI } from '../../types/component/Alert';
import AlertComponent from '../molecules/AlertComponent';
import config from '../../config.json';
import { appRemoveAlertAction } from '../../state/action';

export const Alerts: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const alerts = useSelector(selectAppAlerts);

  const getAlerts = (alerts: Array<AlertI>) => {
    return alerts.map((element: AlertI, index: number) => {
      setTimeout(() => {
        dispatch(appRemoveAlertAction(element));
      }, config.ALERTS.ALERT_VISIBLE_TIMEOUT);
      return (
        <AlertComponent key={index} alert={element.label} type={element.type} />
      );
    });
  };

  return <div>{getAlerts(alerts)}</div>;
};
export default Alerts;
